import { setLoader } from "@/Hooks/Functions";
import { JSONPostRequest } from "@/Hooks/JSONRequest";
import { cn } from "@/lib/utils";
import { Icon } from "@iconify/react";
import { Link, usePage } from "@inertiajs/react";
import { useState } from "react";
import { toast } from "sonner";

export default function LikeUnlike({ item, type = "templates", className = "" }) {
    const { auth } = usePage().props;
    const [isLike, setIsLiked] = useState(item?.is_liked_by_user || false);
    const [count, setCount] = useState(item?.likes_count || 0);

    const removelabel = type !== "products" ? 'Remove favorite' : 'Remove wishlist';
    const addLabel = type !== "products" ? 'Add to favorites' : 'Add to wishlist';
    const removeTips = type !== "products" ? 'Click to remove from favorites' : 'Click to remove from wishlist';
    const addTips = type !== "products" ? 'Click to add to favorites' : 'Click to add to wishlist';

    const doLikeUnlike = () => {
        if (!auth?.user) {
            toast.error("Please login to manage your favorites!")
            return;
        }
        JSONPostRequest({
            href: route('favorite.like-unlike', { type: type, id: item?.id }),
            onResult: (val) => {
                setCount(val.likes_count);
                setIsLiked(val.is_liked);
                toast.success(val.message);
                if (!val.is_liked)
                    window.emitter.emit(`remove-${type}-favorite`, item?.id)
            },
            onLoading: (val) => setLoader(val)
        })
    }
    return (
        <div className={cn("flex gap-x-4 group rounded-full px-2 py-[2px] disabled:cursor-not-allowed cursor-pointer justify-center relative items-center  flex-nowrap", className)}
            disabled={!auth?.user} tip={isLike ? removeTips : addTips}
            onClick={doLikeUnlike}  >
            <div className="flex flex-row w-fit items-center divide-gray-300 divide-x-2">
                <Icon icon={isLike ? "mdi:heart-minus" : 'mdi:heart-plus'} width={30} className={cn("group-hover:text-white text-2xl pr-2", isLike ? "!text-teal-600" : "!text-black")} />
                <span className="text-sm font-semibold pl-3 text-teal-600">{count}</span>
            </div>
            {auth?.user && <span className="text-sm font-semibold hover:underline">{isLike ? removelabel : addLabel}</span>}
            {!auth?.user && <Link href={route('login')} className="text-sm hover:underline hover:text-teal-600 font-semibold">Login to continue</Link>}
        </div>
    )
}


